<template>
  <div>
    <div class="sidebar-wrapper">
      <div class="sidebar-item">
        <div class="item-title">任务状态</div>
        <div class="item-box">
          <ul>
            <li
              :class="{ active: search.timeStatus === null }"
              @click="handleClick({ timeStatus: null })"
            >
              全部
            </li>
            <li
              :class="{ active: search.timeStatus === 'coming' }"
              @click="handleClick({ timeStatus: 'coming' })"
            >
              未开始
            </li>
            <li
              :class="{ active: search.timeStatus === 'going' }"
              @click="handleClick({ timeStatus: 'going' })"
            >
              进行中
            </li>
            <li
              :class="{ active: search.timeStatus === 'finish' }"
              @click="handleClick({ timeStatus: 'finish' })"
            >
              已结束
            </li>
          </ul>
        </div>
      </div>
      <div class="sidebar-item">
        <div class="item-title">
          <span>任务组别</span>
          <a @click="inputVisible = true">新建分组</a>
        </div>
        <div class="item-box">
          <a-input
            class="mt-15"
            placeholder="请输入名称"
            v-if="inputVisible"
            v-focus
            @blur="handleBlur"
          >
            <template #prefix><group-outlined /></template>
          </a-input>
          <ul>
            <li
              :class="{ active: search.campaignGroupId === null }"
              @click="handleClick({ campaignGroupId: null })"
            >
              全部
            </li>
            <li
              v-for="item in groupList"
              :key="item.id"
              :class="{ active: search.campaignGroupId === item.id }"
              @click="handleClick({ campaignGroupId: item.id })"
            >
              <span>{{ item.campaignGroupName }}</span>
              <a-popconfirm
                title="确认删除该标签？"
                @confirm="handleConfirm(item)"
              >
                <a-tooltip title="删除" placement="right">
                  <a class="error-color"><delete-outlined /></a>
                </a-tooltip>
              </a-popconfirm>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
import { GroupOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import activityAPI from '@/api/activity.js';
import { Popconfirm, Tooltip } from 'ant-design-vue';
import { toRaw } from 'vue';

export default {
  components: {
    ATooltip: Tooltip,
    APopconfirm: Popconfirm,
    GroupOutlined,
    DeleteOutlined,
  },
  props: {
    type: String,
    groupList: {
      type: Array,
      default: () => [],
    },
    sidebarVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: {
        timeStatus: null,
        campaignGroupId: null,
      },
      inputVisible: false,
      // 操作栏是否可见
      operationPanelVisible: true,
    };
  },
  created() {
    this.getGroupList();
  },
  methods: {
    getGroupList() {
      activityAPI.getGroupList(`${this.type}:group`).then(({ records }) => {
        this.$emit('update:groupList', records);
      });
    },
    handleBlur({ target }) {
      if (target.value) {
        activityAPI
          .addGroup(`${this.type}:group`, {
            campaignGroupName: target.value,
            label: target.value,
          })
          .then(() => {
            this.$message.success('操作成功');
            this.getGroupList();
          });
      }
      this.inputVisible = false;
    },
    handleConfirm({ id }) {
      activityAPI.deleteGroup(`${this.type}:group`, { ids: [id] }).then(() => {
        this.$message.success('操作成功');
        this.getGroupList();
      });
    },
    handleClick(value) {
      this.$emit('fnSearch', toRaw(Object.assign(this.search, value)));
    },
  },
};
</script>

<style lang="less" scoped>
.sidebar-wrapper {
  margin-right: 30px;
}
.sidebar-item {
  margin-bottom: 20px;
  font-size: 16px;
  .item-title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
    span {
      flex: 1;
    }
    a {
      font-size: 12px;
    }
  }
  .item-box {
    padding: 0 12px;
    border-radius: 8px;
    border: 1px solid @border-color-base;
    .box-title {
      margin-top: 15px;
    }
    ul {
      margin-top: 1em;
      font-size: 14px;
      li {
        display: flex;
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.65);
        border-radius: 15px;
        cursor: pointer;
        transition: 0.2s;
        span {
          flex: 1;
        }
        &:hover {
          padding-left: 20px;
          background-color: @primary-2;
        }
        &.active {
          padding-left: 20px;
          color: #fff;
          background-color: @primary-color;
        }
      }
    }
  }
}
</style>
